/* ==========================================================================
  @imports
========================================================================== */

@import 'variables';
@import 'mixins';


.block-section{
  margin-bottom: 40px;
}
.block{
  padding-top: 30px;
}



  .image-overlay{
    position: relative;
    .fixed-image-wrapper {
      width: 100%;
      // height: 500px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .image-overlay__title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1rem;
    color: #fff;
    text-shadow: 0 0 1rem rgba(0,0,0,.6);
    font-family: $title-font;
    text-align: center;
    font-size: 2rem;
    margin:0 auto;
    font-weight: 700;
}

  .caption::before {
    content: "";
    display: block;
    position: absolute;
    margin-top: -30px;
    width: 100%;
    left: 0;
    height: 25px;
    z-index: 1;
    background-image: url('../assets/ripped-edge_white.png');
  }
  .caption{
    font-family: $copy-font;
    text-align: center;
    font-size: 15px;
    padding: 0px 15px;
    .caption__content{
      margin-top: 20px;
    }
    .button{
      display: inline-block;
      border: 1px solid #005825;
      padding: .5333333333rem .5rem;
      font-size: 1.4666666667rem;
      text-align: center;
      transition: background-color .25s linear;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      color: #fff;
      background-color: #005825;
    }
  }
