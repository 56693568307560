/* ==========================================================================
  Variables
========================================================================== */

@import 'fonts';

$title-font: "Cookbook",sans-serif;
$copy-font: "TypeWriter Regular",sans-serif;
$quote-font: "ValleyGirl",sans-serif;
$fontello: 'fontello';
$headerHeight: 50;

$dark_grey: #252525;
$safety_orange: #FF9800;
$venetian_red: #C10811;
$green: #0A491A;
