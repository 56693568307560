/* ==========================================================================
  @imports
========================================================================== */

@import 'variables';
@import 'mixins';


/* ==========================================================================
  Toast / Snackbar
========================================================================== */

.MuiSnackbarContent-root{
  background-color: #ffffff!important;
}

.MuiSnackbar-root {
  bottom: 10vh !important;
  background-color: #ffffff!important;
  .MuiSvgIcon-root {
    color: #FFF;
  }
}

.MuiSnackbarContent-message {
  // width: 85%;
  text-align: center;
  font-size: 14px;
  padding: 8px 15px;
}

.toast-success {
  .MuiSnackbarContent-root {
    background-color: #ffffff!important;
  }
}

.toast-warning {
  .MuiSnackbarContent-root {
    background-color: #ffffff!important;
  }
}

.toast-success,.toast-warning {
  .toast-message {
    display: inline-block;
    text-align: center;
    // width: calc(90% - 20px);
    // margin-left: 20px;
    padding: 0px 20px;
  }
}
.toast-content{
  font-family: $copy-font;
  color: #000;
}
.toast-action {
  display: block;
  margin-top: 15px;
  text-align: center;

  a {
    display: inline-block;
    padding: 3px 8px;
    border: 2px solid currentColor;
    border-radius: 3px;
    text-transform: uppercase;
    color: white;
  }
}
