/* ==========================================================================
  @imports
========================================================================== */

@import 'variables';
@import 'mixins';


.button{
  text-decoration: none;
  font-family: $title-font;
  border-color: #f49d19;
  color: #fff;
  background-color: #f49d19;
  display: inline-block;
  border: 1px solid #005825;
  padding: .5333333333rem .5rem;
  font-size: 1.4666666667rem;
  text-align: center;
  transition: background-color .25s linear;
}
.section.hero {
    padding-bottom: 0em;
  }
.account-view{
  margin-top: 100px;
  .block{
    height: 510px;
  }
  .account-summary{
    color: #ffffff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: rgba(0,0,0,.7);
  }
  .account{
    background: url('../assets/account_bg.jpg') no-repeat;
  }
  .spend-yeokens{
    background: url('../assets/spend_bg.jpg') no-repeat;
  }
  .donate-yeokens{
    background: url('../assets/donate_bg.jpg') no-repeat;
  }
}



  .hero{
    .image-overlay{
      position: relative;
      .fixed-image-wrapper {
        width: 100%;
        height: 500px;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .welcome-message-wrapper{
        position: absolute;
        left: 0;
        top: 40%;
        right: 0;
        .welcome-message{
          color: #fff;
          padding-top: 1rem;
          padding-bottom: 1rem;
          text-align: center;
          padding: 1rem;
          background-color: rgba(0,0,0,.7);
          .welcome-summary-text{
            margin: 20px auto;
          }
          h1{
            font-family: $title-font;
            line-height: 1em;
          }
          p{
            font-family: $copy-font;
          }
        }
      }
    }
  }

    .page-intro{
      padding-bottom: 20px;
      text-align: center;
      &.page-intro::before{
        content: "";
        display: block;
        position: absolute;
        top: 476px;
        width: 100%;
        left: 0;
        height: 25px;
        z-index: 1;
        background: url('../assets/texture_cream_C.png') center no-repeat;
      }

      img{
        width: inherit;
        margin-top: 20px;
      }
      background: url('../assets/texture_cream_B.jpg');
      .page-intro__img{
        border-width: .6666666667rem;
        border-color: #fff;
        box-shadow: 0 0 1rem #666;
        margin-bottom: 15px;
      }
      span{
        font-family: $copy-font;
        font-size: .8rem;
        line-height: 1.2rem;
      }
      p{
        text-align: center;
        font-family: $quote-font;
        font-size: 2rem;
        line-height: 1.8666666667rem;
      }
    }

img{
  width:inherit;
}
