/* ==========================================================================
  @imports
========================================================================== */

@import 'variables';
@import 'mixins';

/* ==========================================================================
  Camera
========================================================================== */

.camera_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  align-content: center;
  justify-content: center;
  overflow: hidden;
}

.camera-access {
  height: calc(100vh - #{$headerHeight}px);
}

.camera-intro {
  width: 90vw;
  margin: 0 auto;
}

.camera {
  position: relative;
  display: block;
  width: 100vw;
  height: calc(100vh - #{$headerHeight}px);
  object-fit: cover;
  z-index: 1;
}

.overlay {
  position: absolute;
  width: 100vw;
  height: calc(100vh - #{$headerHeight}px);
  top: #{$headerHeight}px;
  z-index: 5;
  &:before {
    @include background-image;
    content: "";
    position: absolute;
    background-image: url("../assets/cross_hair.png");
    background-size: contain;
    height: 100px;
    width: 100px;
    display: block;
    top: 30vh;
    left: calc(40vw - 15px);
  }
}

.canvas {
  position: relative;
  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
}
