.account-view{
  margin-top: 100px;
  .block{
    height: 510px;
  }
  .account-summary{
    color: #ffffff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: rgba(0,0,0,.7);
  }
  .account{
    background: url('../assets/account_bg.jpg') no-repeat;
  }
  .spend-yeokens{
    background: url('../assets/spend_bg.jpg') no-repeat;
  }
  .donate-yeokens{
    background: url('../assets/donate_bg.jpg') no-repeat;
  }
}
