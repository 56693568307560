@import 'variables';

header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  height: 4rem;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 133.333333333rem;
  background-color: rgba(0,0,0,0);
  background-image: url('../assets/nav_bg_green.png');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: $copy-font;
  font-size: 1em;
  &.scan{
    background-image: url('../assets/nav_bg_orange.png');
    .points{
      position: static;
      display: inline-block;
      left: auto;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      margin: 1.1rem auto 0;
      text-align: center;
      color: #ffffff;
      width:85%;
      p{
        margin:0 auto;
      }
    }
    .burger-menu{
      display: inline-block;
      float: right;
      height: 100%;
      background-image: url('../assets/nav_bg_green.png');
      background-position: right center;
      background-repeat: no-repeat;
      background-size: cover;
      & a{
        padding-top: 1rem;
        padding-bottom: 1rem;
        height: 90%;
        font-size: 0;
        vertical-align: middle;
        line-height: 3.5rem;
        color: #ffffff;
      }
      & a::before{
        content: '\f0c9';
        font-family: $fontello;
        color: #ffffff;
        padding: 15px;
        font-size: 1.2rem;
      }
    }
  }
  .view-account, .scan-code, .burger-menu{
    display: inline-block;
  }
  .view-account{
    background-color: #fff;
    margin:0 auto;
  }

  .header__logo {
    position: absolute;
    left: .4rem;
    z-index: 10000;
    margin-top: 0;

    -webkit-transform: rotate(-15deg) translateZ(0);
    transform: rotate(-15deg) translateZ(0);
    img{
      width: 6.8rem;
      height: 6.6rem;
    }
  }
  .dorset__logo{
    width: 110px;
    float: right;
    margin: 7px 10px;
  }
}

.scan-code{
  border: 1px solid #ffffff;
  padding: 5px 10px;
}
