@font-face {
  font-family: Cookbook;
  src: url(../fonts/Cookbook.woff);
}

@font-face {
  font-family: TypeWriter Regular;
  src: url(../fonts/typowriter_regular.woff);
}

@font-face {
  font-family: 'fontello';
  src: url(../fonts/fontello.woff);
}

@font-face {
  font-family: 'ValleyGirl';
  src: url(../fonts/valleygirl_regular-webfont.woff);
}
